<template>
  <b-navbar transparent :spaced="true">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="https://static.iulaanu.com/iulaanu-static/images/iulaanu-logo.png"
          alt="iulaanu.com | Latest jobs in Maldives"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ name: 'EmployerDashboard' }">
        Dashboard
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'EmployerJobs' }">
        Jobs
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'EmployerProfile' }">
        Profile
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'EmployerBilling' }">
        Billing
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <router-link class="button is-primary" :to="{ name: 'CreateJob' }">
            <strong>Post a Job</strong>
          </router-link>
        </div>
      </b-navbar-item>
      <b-dropdown
        v-model="navigation"
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
      >
        <template #trigger>
          <a class="navbar-item" role="button">
            <figure class="image is-24x24">
              <img
                class=""
                style="
                  margin: 0 auto;
                  object-fit: fill;
                  height: 24px;
                  width: 24px;
                "
                :src="activeEmployer.logo"
              />
            </figure>
            <b-icon icon="menu-down"></b-icon>
          </a>
        </template>

        <b-dropdown-item custom aria-role="menuitem">
          Logged as <b>{{ activeEmployer.name }}</b>
        </b-dropdown-item>
        <hr class="dropdown-divider" />
        <b-dropdown-item
          v-for="emp in allEmployers"
          :key="emp.id"
          has-link
          :value="emp.slug"
          aria-role="menuitem"
        >
          <a @click.prevent="setActiveEmployer(emp.id)">
            <div class="columns is-mobile">
              <div class="column is-3">
                <figure class="image is-24x24">
                  <img
                    class=""
                    style="
                      margin: 0 auto;
                      object-fit: fill;
                      height: 24px;
                      width: 24px;
                    "
                    :src="emp.logo"
                  />
                </figure>
              </div>
              <div class="column is-9 pl-2">
                {{ emp.name }}
              </div>
            </div>
          </a>
        </b-dropdown-item>

        <hr class="dropdown-divider" aria-role="menuitem" />
        <b-dropdown-item has-link>
          <a @click.prevent="employerFeedback">Report an issue</a>
        </b-dropdown-item>
        <b-dropdown-item has-link>
          <a @click.prevent="userOnboarding">Add an employer</a>
        </b-dropdown-item>
        <hr class="dropdown-divider" aria-role="menuitem" />
        <b-dropdown-item
          value="logout"
          aria-role="menuitem"
          @click="performLogout"
        >
          Logout
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import axios from "axios";

export default {
  name: "TheNavbar",
  components: {
    //
  },
  data() {
    return {
      user: {},
      navigation: "",
    };
  },
  mounted() {
    this.user = this.$store.state.user;
  },
  methods: {
    setActiveEmployer(id) {
      //start loading
      this.$store.dispatch("setLoading", true);

      //set timeout and change current employer
      setTimeout(() => {
        //set current employer
        this.$store.dispatch("employers/setActiveEmployer", id).then(() => {
          // redirect to dashboard if in another page and unset loading
          if (this.$route.name !== "EmployerDashboard") {
            this.$router.push({
              name: "EmployerDashboard",
            });
          }
          //unset loading
          this.$store.dispatch("setLoading", false);
          // toast open with message
          this.$buefy.toast.open({
            message: "You are now using portal as " + this.activeEmployer.name,
            top: "100px",
            type: "is-success",
            duration: 3000,
          });
        });
      }, 1000);
    },
    performLogout() {
      axios
        .post("/auth/token/logout/")
        .then(() => {
          this.$store.dispatch("logOut");
          this.$router.push("/user/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    userOnboarding() {
      this.$router.push({
        name: "UserOnboarding",
      });
    },
    employerFeedback() {
      this.$router.push({
        name: "EmployerFeedback",
      });
    },
  },
  computed: {
    allEmployers() {
      return this.$store.getters["employers/getEmployers"];
    },
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
};
</script>

<style></style>
