<template>
  <div class="user-admin" :key="activeEmployer.id">
    <the-navbar></the-navbar>
    <div class="container pt-3 pb-3" style="min-height: 70vh">
      <b-notification
        :key="activeEmployer.id"
        type="is-warning is-light"
        aria-close-label="Close notification"
        v-if="showNotification ? true : false"
      >
        {{ message }}
      </b-notification>
      <router-view />
    </div>
    <the-footer></the-footer>
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="false"
    >
    </b-loading>
  </div>
</template>

<script>
import axios from "axios";

import TheNavbar from "../components/navigation/TheNavbar.vue";
import TheFooter from "../components/navigation/TheFooter.vue";

export default {
  name: "AdminLayout",
  data() {
    return {
      isFullPage: true,
      showNotification: false,
      message: "",
    };
  },
  components: {
    TheNavbar,
    TheFooter,
    //
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
  methods: {
    async checkEmployerReady() {
      // get current employer
      const emp = this.$store.getters["employers/getEmployer"];
      // check if employer is ready
      const isReady = await axios.get(`/api/employers/${emp.id}/ready/`);
      // after response, check if employer is ready
      if (isReady.data.ready) {
        this.showNotification = false;
        this.message = "";
      } else {
        this.showNotification = true;
        this.message = isReady.data.message;
      }
    },
  },
  watch: {
    activeEmployer(newVal, oldVal) {
      if (newVal && newVal.id !== oldVal.id) {
        this.checkEmployerReady();
      } else {
        this.checkEmployerReady();
      }
    },
  },
  mounted() {
    //
  },
  created() {
    this.$store.commit("employers/INIT_EMPLOYERS");
    this.$store.commit("employers/INIT_EMPLOYER");
    this.$store.commit("employers/INIT_EMPDATA");
    this.checkEmployerReady();
  },
};
</script>
