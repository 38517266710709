<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>iulaanu.com</strong> is a
        <a target="_blank" href="https://www.iulaanu.com">Job Board</a>
        aimed to help jobseekers land their dream jobs easily and hassle-free.
        Help us improve by submitting a
        <router-link :to="{ name: 'EmployerFeedback' }"> feedback.</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  //
};
</script>
